import logo from './logo.svg';
import './App.css';
import React from 'react';
import Typewriter from "typewriter-effect";
import { BrowserView, MobileView, isBrowser, isMobile , browserName, CustomView } from 'react-device-detect';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Home from "./home"
import About from "./about"

function App() {
  return (
<Router>
  <Routes>
    <Route path = "/" element = {<Home />} />
    <Route path = "/about" element = {<About />} />
    <Route path = "/CV" element = {<Home />} />
    <Route path = "Projects" element = {<Home />} />
    

    </Routes>
  </Router>
  );
}

export default App;
